@font-face {
  font-family: 'Patron Serif';
  src: url('../assets/fonts/PatronSerif/PatronSerif-Bold.woff2') format('woff2'),
       url('../assets/fonts/PatronSerif/PatronSerif-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Patron Serif';
  src: url('../assets/fonts/PatronSerif/PatronSerif-Medium.woff2') format('woff2'),
       url('../assets/fonts/PatronSerif/PatronSerif-Medium.woff') format('woff');
  font-weight: 500; /* Ajusta según el peso */
  font-style: normal;
}

@font-face {
  font-family: 'Rosales';
  src: url('../assets/fonts/Rosales/Rosales-Bold.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Rosales';
  src: url('../assets/fonts/Rosales/Rosales-Regular.otf') format('opentype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Rosales';
  src: url('../assets/fonts/Rosales/Rosales-Light.otf') format('opentype');
  font-weight: light;
  font-style: normal;
}

/**
 * ----------------------------------------
 * Variables
 * ----------------------------------------
 */

$roboto: 'Roboto';
$open-san: 'Open Sans';

$patron-serif: 'Patron Serif';
$patron-serif-bold: 'Patron Serif Bold'; // Ajusta para diferenciar

$rosales: 'Rosales';
$rosales-bold: 'Rosales Bold';
$rosales-light: 'Rosales Light';

/**
 * ----------------------------------------
 * Exports
 * ----------------------------------------
 */

 :export {
  roboto: $roboto;
  open-san: $open-san;

  patron-serif: $patron-serif;
  patron-serif-bold: $patron-serif-bold;

  rosales: $rosales;
  rosales-bold: $rosales-bold;
  rosales-light: $rosales-light;
}
